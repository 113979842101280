// Step 1: Import React
import * as React from 'react'
import { Link } from 'gatsby'
import Layout2 from '../../components/layout2'
import Seo2 from '../../components/seo2'
import Disclaimer from '../../components/disclaimer'

// Step 2: Define your component
const IndexPage = () => {
  return (
    <Layout2 pageTitle="Books">
      <p>A once frowned upon hobby has turned into one of my best past times! There are so many books and so many genres...</p>
      <a href="https://amzn.to/3TaOlgh" target="_blank">Rich Dad Poor Dad</a>
      <Disclaimer />
      </Layout2>
  )
}



// You'll learn about this in the next task, just copy it for now
export const Head = () => <Seo2 title="My Books" />

// Step 3: Export your component
export default IndexPage